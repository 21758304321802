import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Backlink from "../components/back-link"

const Datenschutz = () => {
  return (
    <Layout>
      <SEO title="Datenschutz" />
      <Backlink />
      <div className="grid grid-cols-12 px-4 lg:px-6 min-h-screen mb-32">
        <div className="col-span-12 lg:col-span-6 lg:col-start-7 lg:pl-6">
          <div>
            <h1 className="mb-16">Datenschutz</h1>
            <h2>Grundlegendes</h2>
            <p>
              Diese Datenschutzerklärung soll die Nutzer dieser Website über die
              Art, den Umfang und den Zweck der Erhebung und Verwendung
              personenbezogener Daten durch den Websitebetreiber
            </p>
            <p>
              Theresa Risse <br />
              Gronenborner Weg 12
              <br />
              51377 Leverkusen <br />
            </p>
            <p>informieren.</p>
            <p>
              Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und
              behandelt Ihre personenbezogenen Daten vertraulich und
              entsprechend der gesetzlichen Vorschriften. Da durch neue
              Technologien und die ständige Weiterentwicklung dieser Webseite
              Änderungen an dieser Datenschutzerklärung vorgenommen werden
              können, empfehlen wir Ihnen sich die Datenschutzerklärung in
              regelmäßigen Abständen wieder durchzulesen.
            </p>
            <p>
              Definitionen der verwendeten Begriffe (z.B. “personenbezogene
              Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
            </p>
            <h2>Umgang mit Kontaktdaten</h2>
            <p>
              Nehmen Sie mit uns als Websitebetreiber durch die angebotenen
              Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben
              gespeichert, damit auf diese zur Bearbeitung und Beantwortung
              Ihrer Anfrage zurückgegriffen werden kann. Ohne Ihre Einwilligung
              werden diese Daten nicht an Dritte weitergegeben.
            </p>
            <h2>Rechte des Nutzers</h2>
            <p>
              Sie haben als Nutzer das Recht, auf Antrag eine kostenlose
              Auskunft darüber zu erhalten, welchepersonenbezogenen Daten über
              Sie gespeichert wurden. Sie haben außerdem das Recht auf
              Berichtigung falscher Daten und auf die Verarbeitungseinschränkung
              oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend,
              können Sie auch Ihr Recht auf Datenportabilität geltend machen.
              Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet
              wurden, können Sie eine Beschwerde bei der zuständigen
              Aufsichtsbehörde einreichen.
            </p>
            <h3>Löschung von Daten</h3>
            <p>
              Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur
              Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert,
              haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns
              gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung
              nicht mehr vonnöten sein und es keine gesetzlichen
              Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht
              durchgeführt werden kann, da die Daten für zulässige gesetzliche
              Zwecke erforderlich sind, erfolgt eine Einschränkung der
              Datenverarbeitung. In diesem Fall werden die Daten gesperrt und
              nicht für andere Zwecke verarbeitet.
            </p>
            <h3>Widerspruchsrecht</h3>
            <p>
              Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch
              machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder
              Zeit widersprechen. Muster von datenschutz.orgWenn Sie eine
              Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer
              Person gespeicherten personenbezogenen Daten wünschen oder Fragen
              bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer
              personenbezogenen Daten haben oder erteilte Einwilligungen
              widerrufen möchten, wenden Sie sich bitte an folgende
              E-Mail-Adresse: kontakt@joshuastuebner.com
            </p>
          </div>
        </div>
      </div>
      <Backlink />
    </Layout>
  )
}

export default Datenschutz
